import Headroom from 'headroom.js'
( function( $, window, document, undefined ) {
  'use strict'

  var currentScrollPositon = '0';
  var phoneWindow = jQuery( window ).width() < 480;
  var mobileNavWindow = jQuery( window ).width() < 768;
  var smallFilterScreens = jQuery( window ).width() < 992;

  // safe to load before everything is completely loaded
  $( document ).ready( function() {

    initHeadroom()

    initWaypoints()

    initFormValidator()

    initPreloader()

  } )



  // only load when everything is actually loaded
  $( window ).on( 'load', function() {

    initFlexslider()

    initOwl()

  } )

  var _filterLink = '.filter__link'
  var _filteredItem = '.filtered__item'
  var _filteredImg = '.filtered__image'
  var _teamImg = '.team__item .filtered__image'
  var _projImg = '.proj__item .filtered__image'
  var _teamContent = '.team__content'
  var _projItem = '.proj__item'

  var filterLink = $( _filterLink )
  var filteredItem = $( _filteredItem )
  var filteredImg = $( _filteredImg )
  var teamImg = $( _teamImg )
  var projImg = $( _projImg )
  var teamContent = $( _teamContent )
  var projItem = $( _projItem )


  // just go for it
  $( function() {

    // Turn "no-js" class into "js"
    document.documentElement.className = document.documentElement.className.replace( "no-js", "js" );

    // Event Listeners
    $( document.body ).on( 'click', '.mobile-nav-toggle, nav.mobile a', toggleNav )

    $( document.body ).on( 'click', _filterLink, filterItem )

    $( document.body ).on( 'click', _teamImg, showTeamContent )

    $( document.body ).on( 'click', '.filter__link.all', filterAll )

    $( document.body ).on( 'click', '.filter__title', toggleFilters )

    $( document.body ).on( 'click', '[data-dismiss="modal"]', closeModal )

    // if using headroom, automatically add top padding to the body
    if ( $( '.headroom' ).length && !$( '.hero' ).length ) {
      $( 'body' ).css( 'padding-top', $( '.headroom' ).height() )
    }

    if ( $( '.headroom' ).length ) {
      $( '.headroom ~ article, .headroom ~ footer' ).addClass( 'visible' )
    }


    // Smooth Scolling https://css-tricks.com/snippets/jquery/smooth-scrolling/
    // the higher the '500' number is, the slower is goes.
    // the - 100 in scrollTopVal should match the height of the fixed navigation + 20px
    $( 'a[href*="#"]:not([href="#"])' ).click( function() {
      if ( location.pathname.replace( /^\//, '' ) == this.pathname.replace( /^\//, '' ) && location.hostname == this.hostname ) {
        var target = $( this.hash );
        target = target.length ? target : $( '[name=' + this.hash.slice( 1 ) + ']' );
        if ( target.length ) {
          $( 'html, body' ).animate( {
            scrollTop: target.offset().top - 180
          }, 500 );
          return false;
        }
      }
    } );


    $( ".hero-arrow" ).click( function( event ) {
      $( 'html, body' ).animate( { scrollTop: $( window ).height() }, 500 );
    } );

  } )

  function closeModal () {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  function activate( elem ) { elem.removeClass( 'inactive' ).addClass( 'active' ) }

  function deactivate( elem ) { elem.removeClass( 'active' ).addClass( 'inactive' ) }

  // when you click a filter link
  function filterItem() {

    var category = $( this ).attr( 'href' ) ? $( this ).attr( 'href' ).substring( 1 ) : '';
    var activeItem = $( _filteredImg + '.' + category )
    var inactiveItem = filteredImg.not( '.' + category )

    teamContent.css( 'opacity', 0 )

    deactivate( filterLink )

    deactivate( inactiveItem )

    if ( smallFilterScreens ) {
      filteredImg.parents( _filteredItem ).show()
      inactiveItem.parents( _filteredItem ).hide()
    }

    activate( activeItem )

    activate( $( this ) )

  }

  function showTeamContent() {

    if ( $( '.team__item' ).length > 0 ) {

      deactivate( filteredImg )

      activate( $( this ) )

      var category = $( this ).attr( 'href' ) ? '.' + $( this ).attr( 'href' ).substring( 1 ) : ''
      var allImg = $( _filteredImg + '.inactive' )
      var thisImg = $( this )
      var allContent = $( _filteredImg + '.inactive' ).siblings( _teamContent )
      var thisContent = $( _filteredImg + '.active' ).siblings( _teamContent )
      var thisHeight = ( mobileNavWindow ) ? '10px' : thisImg.height()

      var contentHeight = ( mobileNavWindow ) ? '20px' : thisContent.height();

      allContent.css( 'opacity', 0 )

      thisContent.css( 'display', 'block' )

      //thisImg.css( 'margin-bottom', thisHeight + 60 )

      thisImg.css( 'margin-bottom', contentHeight + 60 )

      thisContent.css( 'margin-top', thisHeight + 40 )

      allContent.css( 'display', 'none' )

      thisContent.delay( 400 ).fadeIn( 400, function() {

        allImg.css( 'margin-bottom', 0 )

        allContent.css( 'margin-top', 0 )

        thisContent.css( 'opacity', 100 )

        thisContent.css( 'visibility', 'visible' )

        thisContent.css( 'pointer-events', 'all' )

      } )
    }
  }

  function filterAll( e ) {
    e.preventDefault()
    filterLink.removeClass( 'active' )
    $( this ).addClass( 'active' )
    filteredImg.removeClass( 'active' ).removeClass( 'inactive' )
    filteredImg.css( {
      opacity: '',
      display: '',
      margin: ''
    } );
    filteredImg.parents( _filteredItem ).show()
  }

  function toggleFilters() {
    if ( smallFilterScreens ) {
      $( '.filter__col--right' ).slideToggle()
      $( '.filter__arrow' ).toggleClass( 'filter__arrow--down' )
    }
  }


  function initHeadroom() {
    if ( $( '.headroom' ).length ) {
      var headroom = new Headroom( document.querySelector( '.headroom' ), {
        'offset': 50,
        'tolerance': 5
      } )
      headroom.init()
    }
  }

  function initWaypoints() {
    if ( $( '.animateIn' ).length ) {
      $( '.animateIn' ).waypoint( function( direction ) {
        $( this.element ).addClass( 'isShown' )
      }, {
        offset: '80%'
      } )
    }
  }

  function initOwl() {
    if ($('.owl-carousel').length) {
      $(".related-projects").owlCarousel({
        loop: true,
        margin:10,
        nav:true,
        dots:false,
        navText:[,],
        responsive:{
        0:{
          items:2,
          slideBy:2,
        },
        768:{
          items:3,
          slideBy:3,
        },
        992:{
          items:4,
          slideBy:4,
        }
      }
    });
	  $(".related-case-studies").owlCarousel({
      loop: true,
      margin:10,
      nav:true,
      dots:false,
      navText:[,],
      responsive:{
      0:{
        items:1,
        slideBy:1,
      },
      768:{
        items:3,
        slideBy:3,
      },
     }
    });
    }
  }

  function initFlexslider() {
    var count = $( ".related-posts-slider .slides a" ).length

    if ( phoneWindow ) {
      var items = 2
    } else if ( mobileNavWindow ) {
      var items = 2
    } else if ( count <= 4 ) {
      items = count
    } else {
      items = 4
    }

    if ( $( '.related-posts-slider' ).length ) {

      $( '.related-posts-slider' ).flexslider( {
        animation: 'slide',
        animationLoop: true,
        controlNav: false,
        directionNav: true,
        itemMargin: 10,
        itemWidth: 300,
        maxItems: items,
        minItems: items,
        move: 1,
        selector: ".slides > a",
        slideshow: false,
        start: function( slider ) { slider.removeClass( 'loading' ); }
      } );

    // } else if ( $( '.flexslider' ).length ) {

    //   $( '.flexslider' ).flexslider( {
    //     animation: 'fade',
    //     animationSpeed: '2000',
    //     controlNav: false,
    //     directionNav: true,
    //     slideshowSpeed: '3000',
    //     //start: function( slider ) {slider.removeClass( 'loading' )}
    //   } )

    }
  }

  function initPreloader() {
    $('.home-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      button: false,
      arrows: false,
      fade: true
    });
    const preloader = $('.js-preloader');
    if (preloader.length) {
      const navbar = $('.navbar');
      const logo = $('.preloader__content svg');
      const content = $('.home .hero-content');
      setTimeout(function() {
        preloader.fadeIn(500);
      }, 100 );
      setTimeout(function() {
        logo.fadeOut(300, function() {
          content.addClass('active');
          navbar.fadeIn(300);
          $('.preloader__content').addClass('loaded');
        });
        preloader.addClass('loaded');

      }, 1000 );
    }
  }

  function toggleNav() {
    if ( $( 'nav.mobile' ).hasClass( 'open' ) ) {
      $( 'nav.mobile' ).removeClass( 'open' )
      $( '.mobile-nav-toggle' ).removeClass( 'open' )
      $( 'body' ).css( {
        overflow: 'auto',
        position: 'static'
      } )
    } else {
      $( 'nav.mobile' ).addClass( 'open' )
      $( '.mobile-nav-toggle' ).addClass( 'open' )
      $( 'body' ).css( {
        overflow: 'hidden',
        position: 'fixed'
      } )
    }
  }

  function initFormValidator() {
    $('form[id="gform_2"]').on('change', function (e) {
      var $reqd = $(this).find('.gfield_contains_required.gfield_visibility_visible').filter(function (i, c) {
        return []
          .concat($(c).find('input[type="text"], textarea, input[type="radio"]').not(':checked').filter(function (i, fl) { return $(fl).val().length == 0; }).get())
          .length;
      });
      if ($reqd.length) {
        $(this).find('input[type="submit"]').attr('disabled', 'disabled');
      } else {
        $(this).find('input[type="submit"]').removeAttr('disabled');
      }
    }).trigger('change');
  }


} )( jQuery, window, document )
